.account-submenu{
    padding-left: 0;
    margin-top: 20px;
    $elm: &;

    &__item {
        display: block;
        margin-top: 0.75em;

        &::before {
            content: "\2022";
            margin-right: 0.2em;
            font-size: #{(16px / 16px) * 1rem};
            line-height: 0;
            vertical-align: middle;
        }

        #{$elm}__item {
            // sub-sub
            margin-bottom: 1em;

            #{$elm}__link {
                font-family: Lemance;
            }
        }
    }

    &__link {
        font-size: #{(14px / 16px) * 1rem};
        font-weight: 100;
        display: inline-block;
        line-height: 1.375em;
        color: map-deep-get($colors, "black", "default");
        font-family: Lemance;

        &:hover{
            text-decoration: underline;
        }
    }
}