.tabpanel {
    background-color: map-deep-get($colors, "white", "default");
    display: none;
    padding: 20px;
    $panel: &;

    @include media-breakpoint-up(md) {
        padding: 40px;
    }

    &--active {
        display: block;
    }
}