.alert {
    $padding-x: 40px;
    $padding-y: 35px;
    background: map-deep-get($colors, "primary", "default");
    color: map-deep-get($colors, "black", "default");
    font-family: Lemance, sans-serif;
    font-size: #{(14px / 16px) * 1rem};
    font-weight: 800;
    line-height: 1.714;
    margin: 20px 0;
    padding: 15px;
    position: relative;

    @include media-breakpoint-up(md) {
        padding-left: #{$padding-x / 2};
        padding-right: #{$padding-x / 2};
    }

    @include media-breakpoint-up(xl) {
        padding-left: $padding-x;
        padding-right: $padding-x;
    }

    &--success {
        background: map-deep-get($colors, "validation", "valid");
        color: map-deep-get($colors, "black", "default");
    }

    &--danger {
        background: map-deep-get($colors, "validation", "invalid");
        color: map-deep-get($colors, "black", "default");
    }
}