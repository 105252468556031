.page-submenu{
    padding-left: 0;
    margin-top: 20px;
    $elm: &;

    &__list {
        #{$elm}__sub {
            display: none;
        }
    }

    &__sub {
        position: relative;
        display: none;
        max-width: 100%;
    }

    &__item {
        position: relative;
        display: block;

        &:not(:last-child) {
        margin-bottom: 1.15em;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            vertical-align: middle;
            display: inline-block;
            width: 20px;
            font-family: "Font Awesome 5 Pro";
            font-size: #{(20px / 16px) * 1rem};
            content: "\f105";
            line-height: 20px;
        }

        &--active {
            // wist geen andere oplossing
            /* stylelint-disable */
            > #{$elm}__link {
                font-weight: 800 !important;
            }

            /* stylelint-enable */

            &::before {
                content: "\f105";
            }
        }

        &--active:not(#{$elm}__item--closed) {
            &#{$elm}__item--hassub::before {
                content: "\f107";
            }

            > #{$elm}__sub {
                display: block;
            }
        }

        #{$elm}__item {
            // sub-sub
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            margin-left: 1.5em;

            #{$elm}__link {
                font-size: #{(14px / 16px) * 1rem};
                font-weight: 400;
            }
        }
    }

    &__link {
        padding-left: 20px;
        font-size: #{(16px / 16px) * 1rem};
        font-weight: 800;
        display: inline-block;
        line-height: 1.375em;
        color: map-deep-get($colors, "black", "default");

        &:hover {
            text-decoration: underline;
        }
    }
}