.banner {
    $banner-elm: &;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    height: 100%;
    padding: 20px;
    position: relative;

    @include media-breakpoint-up(xl) {
        height: 90%;
        padding: 30px;
    }

    &--type1 {
        background-color: rgba(map-deep-get($colors, "primary", "default"), 0.25);
        background-position: center center;
        background-size: cover;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 75vw;

        @include media-breakpoint-up(sm) {
            min-height: 0;
        }

        #{$banner-elm} {
            &__title {
                display: block;
                color: map-deep-get($colors, "white", "default");
                font-size: #{(25px / 16px) * 1rem};
                line-height: 0.9;
                margin-bottom: 0.5em;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    font-size: #{(38px / 16px) * 1rem};
                }
            }

            &__subtitle {
                color: map-deep-get($colors, "black", "default");
                font-size: #{(18px / 16px) * 1rem};
                line-height: 0.9;

                @include media-breakpoint-up(md) {
                    font-size: #{(16px / 16px) * 1rem};
                }
            }
        }
    }

    &--type2 {
        justify-content: center;
        text-align: center;

        #{$banner-elm} {
            &__title-wrapper  {
                align-self: flex-start;
            }

            &__title {
                display: block;
                color: map-deep-get($colors, "white", "default");
                font-size: #{(20px / 16px) * 1rem};
                line-height: 1em;
                margin-bottom: 0.5em;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    font-size: #{(30px / 16px) * 1rem};
                }
            }

            &__subtitle {
                color: map-deep-get($colors, "white", "default");
                font-size: #{(18px / 16px) * 1rem};
                line-height: 0.9;

                @include media-breakpoint-up(md) {
                    font-size: #{(16px / 16px) * 1rem};
                }
            }

            &__image-wrapper {
                align-self: self-end;
                max-width: 100%;
                margin-top: 20px;
                text-align: center;
                width: 100%;
                height: 75%;
            }

            &__image {
                height: 100%;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
        }
    }

    &__button {
        align-items: center;
        background-image: linear-gradient(117deg, transparent 30px, map-deep-get($buttons, "white", "hover-bg") 10%);
        background-position: bottom left;
        bottom: 0;
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        height: 50px;
        padding-left: 40px;
        position: absolute;
        right: 0;
        width: 70px;
    }

    #{$banner-elm}__button-icon {
        transition: $transition-default;
    }

    &:hover {
        #{$banner-elm}__button-icon {
            margin: auto -5px auto 5px;
        }
    }
}
