.favorites {
    background-image: linear-gradient(-7deg, map-deep-get($colors, "white", "default") 40%, map-deep-get($colors, "black", "default") 10%);
    padding-top: 25px;
    padding-bottom: 50px;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding-top: 100px;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__list {
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}