.panel {
    $padding-x: 40px;
    $padding-y: 35px;
    background-color: map-deep-get($colors, "white", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 20px;
    padding: #{$padding-y / 3} #{$padding-x / 3};

    @include media-breakpoint-up(md) {
        padding: #{$padding-y / 2} #{$padding-x / 2};
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-y $padding-x;
    }

    &--gray {
        background-color: map-deep-get($colors, "gray", "light");
    }

    &--payment__body{
        margin-top: 20px;
    }

    &--small{
        @include media-breakpoint-up(xl) {
            padding: #{$padding-y / 2} #{$padding-x / 2};
        }
    }
}

.backbutton {
    &--mobile {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        &--mobile {
            display: inline-block;
        }
    }
}