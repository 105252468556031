.shop-filters {
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 60px;
    padding: 25px;
    width: 100%;
    $elm: &;

    @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 25px;
    }

    @include media-breakpoint-up(lg) {
        padding: 30px;
    }

    &__list {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__title {
        font-size: #{(14px / 16px) * 1rem};
        margin-bottom: 1.5em;
        display: block;
    }

    &__list--active {
        #{$elm}__sub-list-item {
            &:nth-child(n+6) {
                display: block;
            }
        }
    }

    &__sub-list-item {
        &:nth-child(n+6) {
            display: none;
        }
    }

    &--prices{
        border-bottom: 0;
        padding-bottom: 0;
    }

    // voor mobiel totale toggle knop

    &__toggle {
        align-items: center;
        background: map-deep-get($colors, "primary", "default");
        bottom: 10px;
        border-radius: 2px;
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        //height: 50px;
        justify-content: center;
        position: fixed;
        right: 10px;
        //width: 50px;
        z-index: 95;
        font-size: 14px;
        //flex-flow: column wrap;
        padding: 15px 10px;
    }

    // voor detail filter toggle knop

    &__filter-toggle {
        &--more,
        &--less{
            cursor: pointer;
            font-size: 14px;
            font-weight: 900;

            &::after {
                content: "\f107";
                font-family: "Font Awesome 5 Pro";
            }
        }

        &--less{
            display: none;

            &::after {
                content: "\f106";
            }
        }
    }

    &__list--active #{$elm}__filter-toggle {
        &--more {
            display: none;
        }

        &--less {
            display: block;
        }
    }

    &__reset {
        margin-top: 30px;
        display: block;
    }

    &-fixed {
        background-color: map-deep-get($colors, "gray", "light");
        border: 1px solid map-deep-get($colors, "gray", "default");
        bottom: 0px;
        height: 70px;
        left: 0px;
        padding: 10px;
        position: fixed;
        right: 0;
        z-index: 93;

        transition: $transition-default;
        transform: translateX(-100%);

        &--expanded {
            transform: translateX(0);
        }
    }
}