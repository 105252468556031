.searchbar{
    position: relative;
    display: inline-block;
    width: 100%;

    @include media-breakpoint-up(md) {
        transition: all ease-in-out .2s;
        position: absolute;
        top: 0;
        right: 40px;
        bottom: 0;
        width: 0;
        overflow: hidden;

        &--visible {
            width: 100%;
            padding-left: 60px;
        }
    }

    @include media-breakpoint-up(lg) {
        overflow: auto;
        position: relative;
        top:auto;
        right: auto;
        bottom:auto;
        width: 255px;
    }

    &__wrapper{
        @include media-breakpoint-up(md) {
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }

    &__input {
        height: 22px;
        font-size: #{(12px / 16px) * 1rem};
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            height: 40px;
            padding: 10px 20px;
        }

        &, &:focus {
            border: 0;
        }
    }

    &__submit{
        background: none;
        border: none;
        font-size: 14px;
        line-height: 22px;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        width: 22px;

        @include media-breakpoint-up(md) {
            height: 100%;
            width: 40px;
        }

        @include media-breakpoint-up(lg) {
            line-height: 40px;
        }
    }

    &__toggle {
        padding: 5px;
        background: map-deep-get($colors, "primary", "dark");
        display: inline-flex;
        height: 2em;
        width: 2em;
        border-radius: 2px;
        vertical-align: middle;
        color: white;
        align-items: center;
        justify-content: center;
    }
}