.payment-cc {
    display: none;
    $elm: &;

    &__wrapper {
        display: inline-block;
    }

    &__icon{
        width: 50px;
        height: 50px;
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid map-deep-get($colors, "gray", "default");
        border-radius: 5px;
        cursor: pointer;
    }

    &:checked + #{$elm}__icon {
        background: map-deep-get($colors, "gray", "default");
        border-color: map-deep-get($colors, "black", "default");
    }
}