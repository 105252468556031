.subsite__logo {

    .st0 {
        fill: map-deep-get($colors, "black", "default");
    }

}

.header__logo {
    text-align: center;
    transition: $transition-default;
    z-index: 1;
    position: relative;
    $logo-elm: &;

    @include media-breakpoint-up(md) {
        margin-top: -5%;
        display: inline-block;
        width: 100%;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 2.5%;
    }

    &-link{
        display: inline-block;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(xxl) {
            margin-top: 10px;
        }
    }

    svg {
        display: inline-block;
        margin: 5px;
        vertical-align: middle;
        width: 105px;
        max-width: 80%;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            width: 157px;
            max-width: 100%;
            margin: 0;
        }

        /* stylelint-disable */
        //classes van path in svg

        .st0 {
            fill: map-deep-get($colors, "black", "default");
        }

        .st1 {
            fill: map-deep-get($colors, "primary", "default");
        }

        .st2 {
            fill: map-deep-get($colors, "primary", "dark");
        }

        .st3 {
            fill: map-deep-get($colors, "primary", "medium");
        }
        /* stylelint-enable */
    }
}

.product__logo {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top:0;
    transition: $transition-default;
    z-index: 1;
    $logo-elm: &;

    svg {
        display: inline-block;
        margin: 5px;
        max-height: 180px;
        vertical-align: middle;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            margin: 15px 0 0;
        }

        /* stylelint-disable */
        //classes van path in svg
        #path26 {
            fill: lighten(map-deep-get($colors, "black", "default"), 75%);
        }

        #path28 {
            fill: lighten(map-deep-get($colors, "black", "default"), 75%);
        }
        /* stylelint-enable */
    }
}

.logo {
    &__text {
        display: block;
        text-align: center;
        margin: 0.5em 0 0;
        transition: $transition-default;
    }

    &__name,
    &__payoff {
        color: map-deep-get($colors, "black", "default");
        text-transform: uppercase;
        display: block;
    }

    &__name {
        font-size: #{(14px / 16px) * 1rem};
        font-weight: 900;
        font-family: Nominee;
        line-height: 1.5;
        transition: $transition-default;

        @include media-breakpoint-up(xxl) {
            font-size: #{(16px / 16px) * 1rem};
        }
    }

    &__payoff {
        font-size: #{(8.5px / 16px) * 1rem};
        line-height: 1;
    }
}

/* stylelint-disable */
//afhankelijk van parent class
@include media-breakpoint-up(md) {
    .header--scrolled,
    .site-wrapper {

        .header__logo {
            display: inline;
        }

        .header__logo-link {
            margin-top: 0;
        }

        .logo__text {
            display: inline-block;
            margin: 0 0 0 1em;
            text-align: left;
            vertical-align: middle;
        }

        .header__logo-wrapper {
            margin: 0;
            padding: 0;
            background: map-deep-get($colors, "white", "default");
            display: inline-flex !important;
            height: auto;
            align-items: center;
            justify-content: center;
            align-self: stretch;
        }
    }
}
/* stylelint-enable */
