.checkout-nav {
    display: flex;
    margin-bottom: 20px;

    &__step {
        background-color: map-deep-get($colors, "gray", "light");
        color: map-deep-get($colors, "black", "default");
        font-size: #{(18px / 16px) * 1rem};
        font-weight: 800;
        display: none;
        text-align: center;
        width: 100%;
        line-height: 1.111;
        padding: 15px;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: 33.333%;
        }

        &--active {
            background: map-deep-get($colors, "primary", "default");
            display: inline-block;
        }
    }
}