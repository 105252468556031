﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 900;
    font-size: #{(35px / 16px) * 1rem};
    line-height: 1.2em;
    @include media-breakpoint-up(md) {
        font-size: #{(45px / 16px) * 1rem};
    }
}

h2, .h2 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 900;
    font-size: #{(27px / 16px) * 1rem};
    line-height: 1.2em;
    @include media-breakpoint-up(md) {
        font-size: #{(30px / 16px) * 1rem};
    }
}

h3, .h3 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 900;
    font-size: #{(22px / 16px) * 1rem};
    line-height: 1.2em;

    @include media-breakpoint-up(md) {
        font-size: #{(24px / 16px) * 1rem};
    }
}

h4, .h4 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 800;
    font-size: #{(18px / 16px) * 1rem};
    line-height: 1.2em;
}

h5, .h5 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 800;
    font-size: #{(16px / 16px) * 1rem};
    line-height: 1.2em;
}

h6, .h6 {
    //Insert styling here
    font-family: Nominee;
    font-weight: 800;
    font-size: #{(14px / 16px) * 1rem};
    line-height: 1.2em;
}