.breadcrumbs{
    width: 100%;
    background: map-deep-get($colors, "white", "default");
    padding: 20px 0;
    display: none;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;
    }

    &__list-item {
        display: inline-block;
    }

    &__item {
        transition: $transition-default;
        color: map-deep-get($colors, "gray", "dark");
        font-size: #{(12px / 16px) * 1rem};
        text-align: left;

        &:hover {
            color: map-deep-get($colors, "black", "default");
            text-decoration: underline;
        }

        &--active {
            color: map-deep-get($colors, "black", "default");

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__separator {
        color: map-deep-get($colors, "gray", "dark");
        margin-left: 0.25em;
        margin-right: 0.25em;
        vertical-align: middle;
    }
}