.account {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.account-menu {
    display: inline-block;
    $menu: &;

    &__item {
        text-transform: uppercase;
        font-weight: 800;
        font-family: Nominee;
        line-height: 25px;
    }

    &__icon {
        font-size: #{(20px / 16px) * 1rem};
        margin-right: 0.25em;
    }

    &__link {
        color: map-deep-get($header, "color", "default");
    }

    &__sub {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 10px;
        background: map-deep-get($header, "background", "default");
    }

    &__sub-link {
        font-weight: 100;
        font-family: Lemance;
        font-size: #{(14px / 16px) * 1rem};
        color: map-deep-get($header, "color", "default");
    }

    &:hover{
        #{$menu}__sub {
            display: block;
        }
    }
}