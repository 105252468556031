// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    .intro {
        font-size: rem-calc(18);
        margin-top: 0;
        margin-bottom: rem-calc(32);
    }

    p {
        margin: 0 0 rem-calc(16);
    }

    h1, h2, h3, h4, h5, h6 {
        margin: rem-calc(32) 0 rem-calc(8);
    }

    img {
        display: inline-block;
        max-width: 100%;
        height: auto;
    }

    a:not(.button){
        color: map-deep-get($colors, "primary", "default");
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "primary", "default");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }

    .button--icon {
        .button__icon {
            display: none;
        }

        &:after {
            content: '\f30b';
            font-family: "Font Awesome 5 Pro";
            line-height: 1em;
            position: absolute;
            right: 0;
            top: 37%;
            width: 45px;
            z-index: 10;
            transition: $transition-default;
        }
    }
    .button--icon.button--black {
        &:after {
            color: map-deep-get($buttons, "black", "background");
        }
        &:hover {
            &:after {
                color: map-deep-get($buttons, "black", "color");
            }
        }
    }
}

/* stylelint-enable */
