.contact{
    &__icon-wrapper{
        position: relative;
        display: inline-block;
        line-height: 0;

        svg {
            width: 100%;
            display: inline-block;
            height: 100%;

            @include media-breakpoint-up(md) {
                width: 60px;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: map-deep-get($colors, "white", "default");
        font-size: #{(20px / 16px) * 1rem};

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -0.5em;
            margin-left: -0.5em;
            line-height: 1em;
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        display: block;
    }

    &__mailto {
        color: map-deep-get($colors, "black", "default");
        display: block;
        text-decoration: underline;

        &::after {
            content: "\f105";
            font-family: "Font Awesome 5 Pro";
        }
    }
}