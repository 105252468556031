.payment {
    $elm: &;
    padding: 50px;

    &--loading {
        font-size: #{(20px / 16px) * 1rem};
        text-align: center;
    }

    &__icon {
        font-size: #{(50px / 16px) * 1rem};
    }

    @include media-breakpoint-down(sm) {
        &__button {
            display: none;
        }
    }
}