$nav: ".navigation";

.shop-navigation {
    $elm: &;

    & #{$elm}__subs.navigation__sub {
        width: calc(100vw - 120px - 120px - 20px);

        @include media-breakpoint-up(md) {
            width: calc(100vw - 212px - 212px - 20px);

            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 100%;
                height: 20px;
            }
        }

        @include media-breakpoint-up(xl) {
            width: calc(100vw - 310px - 20px);
        }
    }

    &--hassub:hover {
        #{$elm}__subs.navigation__sub {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    &__subs .navigation {
        &__item {
            width: 50%;
            margin-bottom: 1em;

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }

            #{$nav}__link {
                font-weight: 500;

                @include media-breakpoint-between(md, lg) {
                    font-size: #{(14px / 16px) * 1rem};
                }
            }
        }

        &__items {
            margin-top: 1em;

            #{$nav}__item {
                width: 100%;
                padding: 0;

                #{$nav}__link{
                    font-weight: 100;
                    font-size: #{(14px / 16px) * 1rem};
                    text-transform: none;

                    @include media-breakpoint-between(md, lg) {
                        font-size: #{(13px / 16px) * 1rem};
                    }
                }
            }
        }
    }
}