.login-card {
    $padding-x: 35px;
    $padding-y: 40px;
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    height: 100%;
    margin-bottom: 20px;
    padding: #{$padding-y / 4} #{$padding-x / 4};

    @include media-breakpoint-up(md) {
        padding: #{$padding-y / 2} #{$padding-x / 2};
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-y $padding-x;
    }

    &__header {
        margin-bottom: 1em;
    }
}