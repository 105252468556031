.shop-aside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background: white;
    padding-top: 110px;
    overflow: scroll;
    transition: $transition-default;
    transform: translateX(-100%);

    &-expanded {
        overflow: hidden;
    }

    &--expanded {
        transform: translateX(0);
    }

    @include media-breakpoint-up(md) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        padding-top: 0;
        overflow: auto;
        transform: none;
    }
}