.page-aside {
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    padding: 25px;
    margin-bottom: 30px;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding: 30px;
    }

    &__title {
        font-size: #{(19px / 16px) * 1rem};
        padding: 5px 0;
    }

    &--primary {
        background-color: map-deep-get($colors, "primary", "light");
    }

    &--submenu {
        padding: 25px 5px 25px 10px;

        @include media-breakpoint-up(lg) {
            padding: 30px 10px 30px 20px;
        }
    }
}