.shoppingcart-icon-wrapper {
    position: relative;
    display: inline-block;
    width: 2.15rem;
    height: 2.15rem;
}

.shoppingcart-icon {
    width: 2.15rem;
    vertical-align: middle;
    display: inline-block;

    svg {
        width: 100%;
    }

    &:not(.active) {
        display: none;
    }

    &__amount {
        background: map-deep-get($colors, "white", "default");
        border-radius: 50%;
        color: map-deep-get($colors, "black", "default");
        display: block;
        font-family: Nominee;
        font-weight: 900;
        font-size: #{(10px / 16px) * 1rem};
        height: 1.3em;
        margin-top: -0.65em;
        margin-left: -0.65em;
        padding: 0 0.25rem;
        position: absolute;
        right: -15%;
        top: 0;
        text-align: center;
    }
}