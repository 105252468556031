.add-to-cart-message {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0;
    transform: translate(-50%, 150%);
    bottom: 0;
    position: fixed;
    margin-bottom: 30px;
    left: 50%;
    padding: #{(20 / 16) + "em"} #{(30 / 16) + "em"} #{(15 / 16) + "em"} #{(20 / 16) + "em"};
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.5);
    z-index: 999999;
    width: 80%;
    $elem: &;

    @include media-breakpoint-up(md) {
        width: auto;
    }

    &--active {
        transform: translate(-50%, 0%);
        opacity: 1;
        background-color: map-deep-get($colors, "white", "default")
    }

    &--success {
        background-color: map-deep-get($colors, "primary", "light");

        #{$elem + __text} a {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--danger {
        background-color: map-deep-get($colors, "validation", "invalid");

        #{$elem + __text} a {
            color: map-deep-get($colors, "validation", "invalid-dark");
        }
    }

    &__close {
        position: absolute;
        top: #{(7.5 / 16) + "em"};
        right: #{(7.5 / 16) + "em"};
        color: map-deep-get($colors, "black", "default");
    }

    //
    /* stylelint-disable */
    a,
    &__text {
        line-height: 1;
    }

    &__text {
        vertical-align: center;
    }

    a {
        text-decoration: underline;
        font-weight: 700;
        display: inline-block;
    }
    /* stylelint-enable */
}
