.cart {
    &__title {
        margin-bottom: 1em;
    }

    &__list {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__list-item {
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.428em;
        padding-left: 1em;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0.35em;
            left: 0;
            line-height: 1.248em;
            border-radius: 50%;
            height: 0.35rem;
            width: 0.35rem;
            background: map-deep-get($colors, "black", "default");
        }
    }

    &__list-link {
        color: map-deep-get($colors, "black", "default");

        &:hover {
            text-decoration: underline;
        }
    }

    &__label {
        font-size: #{(14px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1.428em;
    }

    &__value {
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.428em;
    }
}