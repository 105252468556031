.shop-categories {
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 25px;
    padding: 25px;
    width: 100%;
    $elm: &;

    @include media-breakpoint-up(lg) {
        padding: 30px;
    }

    &__list {
        #{$elm}__sub {
            display: none;
        }
    }

    &__item {
        display: block;
        margin-bottom: 1.15em;

        &::before {
            content: "\f105";
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            margin-right: 0.25em;
            font-size: #{(20px / 16px) * 1rem};
            line-height: 0;
            vertical-align: middle;
        }

        #{$elm}__item {
            // sub-sub
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            margin-left: 1em;

            &::before {
                content: "\2022";
                margin-right: 0.25em;
                line-height: 0;
                font-size: #{(14px / 16px) * 1rem};
                vertical-align: middle;
            }

            #{$elm}__link {
                font-family: Lemance;
                font-weight: 400;
                font-size: #{(14px / 16px) * 1rem};
            }
        }

        &--hassub {
            // afwachten of dit pijltje wel naar beneden moet wijzen

            /* &::before {
                content: "\f107";
            } */

            &#{$elm}__item--active {
                &::before {
                    content: "\f107";
                }

                #{$elm}__sub {
                    display: block;
                }
            }
        }
    }

    &__link {
        font-size: #{(16px / 16px) * 1rem};
        font-weight: 800;
        display: inline-block;
        line-height: 1.375em;
        color: map-deep-get($colors, "black", "default");
        font-family: Nominee;

        &:hover {
            text-decoration: underline;
        }
    }
}