.accessoire {
    border-top: 1px solid map-deep-get($colors, "gray", "default");
    padding: 20px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-right: -#{($grid-gutter-width / 2)};
    margin-left: -#{($grid-gutter-width / 2)};
    background-color: map-deep-get($colors, "white", "default");

    $elm: &;

    &__image {
        width: 30%;
        height: 0;
        padding-top: 25%;
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: map-deep-get($colors, "gray", "default");
    }

    &__content {
        width: 70%;
        padding-left: 30px;
    }

    &__title {
        font-size: #{(15px / 16px) * 1rem};
        line-height: 1.2em;
        margin-bottom: 1.2em;
    }

    &__price {
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        font-family: Nominee;
        font-size: #{(15px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1.2em;
        margin-top: 20px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        width: 100%;
        -webkit-font-smoothing: antialiased;
    }

    &__vat {
        font-size: #{(10px / 16px) * 1rem};
    }

    &__delivery {
        font-family: Lemance;
        font-size: #{(12px / 16px) * 1rem};
    }

    &__link--detail {
        display: flex;
        color: map-deep-get($colors, "black", "default");
        width: 100%;
    }

    &__bottom {
        // - +1 vanwege border
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__link--to-cart {
        align-items: center;
        background-color: map-deep-get($colors, "primary", "default");
        background-image: linear-gradient(117deg, map-deep-get($buttons, "white", "background") 30px, map-deep-get($buttons, "white", "hover-bg") 10%);
        background-position: bottom left;
        bottom: 0;
        display: inline-flex;
        height: 50px;
        padding-left: 30px;
        position: absolute;
        right: 0;
        width: 75px;

        #{$elm}-to-cart-wrapper {
            position: relative;
            display: inline-block;
        }

        #{$elm}-to-cart-icon {
            display: block;
            transition: $transition-default;
        }

        #{$elm}-to-cart-icon--hover {
            transition: $transition-default;
            position: absolute;
            bottom: 0;
            margin-bottom: 0.1em;
            opacity: 0;
            left: 0;
        }

        &:hover {
            #{$elm}-to-cart-icon {
                opacity: 0;
            }

            #{$elm}-to-cart-icon--hover {
                opacity: 1;
            }
        }

        svg {
            width: #{(35px / 16px) * 1rem};
            display: block;
        }
    }
}