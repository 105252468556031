.mb-gutter{
    margin-bottom: $grid-gutter-width;

    &--half {
        margin-bottom: #{$grid-gutter-width / 2};
    }
}

.mt-gutter {
    margin-top: $grid-gutter-width;

    &--half {
        margin-top: #{$grid-gutter-width / 2};
    }
}

@include media-breakpoint-up(md) {
    .mb-md-gutter{
        margin-bottom: $grid-gutter-width;
    }

    .mt-md-gutter {
        margin-top: $grid-gutter-width;
    }
}

@include media-breakpoint-up(lg) {
    .mb-lg-gutter{
        margin-bottom: $grid-gutter-width;
    }

    .mt-lg-gutter {
        margin-top: $grid-gutter-width;
    }
}

@include media-breakpoint-up(xl) {
    .mb-xl-gutter{
        margin-bottom: $grid-gutter-width;
    }

    .mt-xl-gutter {
        margin-top: $grid-gutter-width;
    }
}

.text-center{
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.h-100 {
    height: 100%;
}

.mb-0 {
    margin-bottom: 0;
}

.site-container{
    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
        padding-left: 68px;
        padding-right: 68px;
    }

    @include media-breakpoint-up(xxl) {
        padding-left: 113px;
        padding-right: 113px;
    }
}

.page-title {
    margin-top: 0;
    margin-bottom: 0.6em;

    @include media-breakpoint-up(md) {
        margin-bottom: 1.5em;
    }
}

[v-cloak] {
    display: none;
}