.usps {
    background: map-deep-get($colors, "white", "default");
    border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 20px;
    padding: 20px 0;
    width: 100%;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    $usps-elm: &;

    &__list {
        &::after {
            content: "flickity";
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;

            &::after {
                content: "";
            }
        }
    }

    &__list-item {
        display: inline-block;
        font-size: #{(14px / 16px) * 1rem};
        margin-left: 1.5em;
        width: 100%;
        text-align: center;
        font-weight: 800;

        @include media-breakpoint-up(md) {
            text-align: right;
        }

        @include media-breakpoint-up(lg) {
            flex: unset;
            max-width: none;
            width: auto;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 2.5em;
        }

        &::before {
            content: "\f00c";
            font-family: "Font Awesome 5 Pro";
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-size: #{(15px / 16px) * 1rem};
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;
            line-height: 1;
            margin-right: 0.3em;
            color: map-deep-get($colors, "validation", "valid");
        }
    }

    &--home {
        background: none;
        border: none;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding-right: $grid-gutter-width;
        }

        #{$usps-elm}__list-item {
            color: map-deep-get($colors, "white", "default");
        }
    }
}