.brands {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__list {
        margin-top: 25px;

        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
    }
}