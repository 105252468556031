.product {
    background-color: map-deep-get($colors, "white", "default");
    border-top: 1px solid map-deep-get($colors, "gray", "default");
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 20px;
    margin-right: -#{($grid-gutter-width / 2)};
    margin-left: -#{($grid-gutter-width / 2)};
    padding: 20px 20px 0;
    position: relative;
    transition: $transition-default;

    @include media-breakpoint-up(sm) {
        border-left: 1px solid map-deep-get($colors, "gray", "default");
    }

    @include media-breakpoint-up(md) {
        border: 1px solid map-deep-get($colors, "gray", "default");
        margin-right: 0;
        margin-left: 0;
    }

    $elm: &;

    &__image {
        width: 30%;
        height: 0;
        padding-top: 30%;
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;

        @include media-breakpoint-up(md) {
            background-position: center center;
            padding-top: 100%;
            width: 100%;
        }
    }

    &__content {
        width: 70%;
        padding-left: 30px;

        @include media-breakpoint-up(md) {
            align-content: flex-end;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            width: 100%;
        }
    }

    &__title {
        font-size: #{(15px / 16px) * 1rem};
        line-height: 1.2em;
        margin-bottom: 1.2em;

        @include media-breakpoint-up(md) {
            align-self: unset;
            display: inline-block;
            font-size: #{(18px / 16px) * 1rem};
            height: 100%;
            width: 100%;
        }
    }

    &__specs {
        width: 100%;
    }

    &__specs-item {
        display: list-item;
        font-size: #{(12px / 16px) * 1rem};
        padding-left: 1em;
        position: relative;

        &::before {
            content: "\2022";
            position: absolute;
            left: 0;
            top: 0;
            line-height: 1em;
        }

        @include media-breakpoint-up(md) {
            font-size: #{(14px / 16px) * 1rem};
        }
    }

    &__price {
        font-family: Nominee;
        font-size: #{(15px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1.2em;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 20px;
        width: 100%;
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            border-bottom: 1px solid map-deep-get($colors, "gray", "default");
            font-size: #{(18px / 16px) * 1rem};
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    &__vat {
        font-size: #{(10px / 16px) * 1rem};

        @include media-breakpoint-up(md) {
            font-size: #{(12px / 16px) * 1rem};
        }
    }

    &__delivery {
        font-family: Lemance;
        font-size: #{(12px / 16px) * 1rem};

        @include media-breakpoint-up(md) {
            font-size: #{(14px / 16px) * 1rem};
        }
    }

    &__link--detail {
        display: flex;
        color: map-deep-get($colors, "black", "default");
        width: 100%;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__bottom {
        // - +1 vanwege border
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__link--to-cart {
        position: relative;
    }

    &__button {
        align-items: center;
        background-image: linear-gradient(117deg, transparent 30px, map-deep-get($buttons, "white", "hover-bg") 10%);
        background-position: bottom left;
        bottom: 0;
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        height: 50px;
        padding-left: 40px;
        position: absolute;
        right: 0;
        width: 70px;
    }

    #{$elm}__button-icon {
        transition: $transition-default;
    }

    &:hover {
        border-color: map-deep-get($colors, "primary", "default");

        #{$elm}__button-icon {
            margin: auto -5px auto 5px;
        }
    }
}

/**
 * Backdrop voor basicLightbox
 */
.backdrop {
    background: map-deep-get($colors, "black", "default");
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    transition: opacity .4s ease;
    top: 0;
    z-index: 999;

    &--visible {
        opacity: 0.8;
    }
}