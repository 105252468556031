.button {
    align-items: center;
    background-color: map-deep-get($buttons, "white", "default");
    background-position: top 0 left 100%;
    background-size: calc(200% + 50px) 100%;
    background-repeat: no-repeat;
    border-radius: 0;
    border: 0;
    color: map-deep-get($colors, "black", "default");
    cursor: pointer;
    display: inline-flex;
    line-height: 1.428em;
    font-weight: 900;
    font-size: #{(14px / 16px) * 1rem};
    text-transform: uppercase;
    min-height: 50px;
    padding: 15px 25px;
    text-align: center;
    transition: $transition-default;
    $button: &;

    &:hover{
        background-position: top 0 left 0;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
    }

    &--text {
        padding: 0;
        min-height: 0;
        background: none;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--white {
        background-color: map-deep-get($buttons, "white", "background");
        background-image: linear-gradient(117deg, map-deep-get($buttons, "white", "hover-bg") 50%, map-deep-get($buttons, "white", "background") 0);
        color: map-deep-get($buttons, "white", "color");

        &:hover{
            color: map-deep-get($buttons, "white", "hover-color");
        }
    }

    &--black {
        background-color: map-deep-get($buttons, "black", "background");
        background-image: linear-gradient(117deg, map-deep-get($buttons, "black", "hover-bg") 50%, map-deep-get($buttons, "black", "background") 0);
        color: map-deep-get($buttons, "black", "color");

        &:hover{
            color: map-deep-get($buttons, "black", "hover-color");
        }
    }

    &--primary {
        background-color: map-deep-get($buttons, "primary", "background");
        background-image: linear-gradient(117deg, map-deep-get($buttons, "primary", "hover-bg") 50%, map-deep-get($buttons, "primary", "background") 0);
        color: map-deep-get($buttons, "primary", "color");

        &:hover{
            color: map-deep-get($buttons, "primary", "hover-color");
        }
    }

    // knop met geel vlak

    &--icon {
        background-image: linear-gradient(-63deg, map-deep-get($buttons, "primary", "background") 50px, transparent 0);
        background-position: top 0 right 0;
        background-size: calc(100% + 60px) 100%;
        background-repeat: no-repeat;
        padding-right: 60px;
        position: relative;
        z-index: 0;

        &:hover{
            background-position: top 0 right -60px;
        }

        #{$button}__icon {
            line-height: 1em;
            margin-top: -0.75em;
            position: absolute;
            right: 0;
            transition: $transition-default;
            top: 50%;
            width: 45px;
            z-index: 10;

            svg {
                transition: $transition-default;
            }
        }

        .fa{
            font-size: 1.5em;
        }
    }

    &--icon.button--black {
        #{$button}__icon {
            color: map-deep-get($buttons, "black", "background");
        }

        svg {
            fill: map-deep-get($buttons, "black", "background");
        }

        &:hover {
            #{$button}__icon {
                color: map-deep-get($buttons, "black", "color");
            }

            svg {
                fill: map-deep-get($buttons, "black", "color");
            }
        }
    }
}
