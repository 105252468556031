.product--detail {
    $slider-elm: &;

    &__title {
        margin-bottom: 20px;
    }

    &__slider {
        border: 1px solid map-deep-get($colors, "gray", "default");
        height: 250px;

        @include media-breakpoint-up(md) {
            height: 350px;
        }
    }

    &__slider-wrapper {
        position: relative;
        margin-bottom: 30px;
    }

    &__slider__label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 30px;
        border-top-left-radius: 0;
        z-index: 10;
    }

    &__slider {
        border: 1px solid map-deep-get($colors, "gray", "default");
        height: 250px;

        @include media-breakpoint-up(md) {
            height: 350px;
        }

        /* stylelint-disable */
        .flickity-button {
            &.previous,
            &.next {
                display: none;
            }
        }

        &.is-fullscreen {
            border-color: transparent;

            .flickity-button {
                &.previous,
                &.next {
                    display: block;
                }
            }
        }
        /* stylelint-enable */
    }

    &__slide {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        cursor: pointer;
        display: flex;
        height: 100%;
        padding: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 20px;
        }

        @include media-breakpoint-up(lg) {
            padding: 25px;
        }

        @include media-breakpoint-up(xl) {
            padding: 30px;
        }
    }

    // slider nav

    &__slider-nav {
        margin-left: -5px;
        margin-right: -5px;
        margin-top: 10px;
    }

    &__slide-nav {
        display: inline-block;
        height: 100px;
        padding: 0 5px;
        width: 33.333%;

        @include media-breakpoint-up(md) {
            height: 115px;
        }

        &.is-selected #{$slider-elm + "__slide-nav-image-wrapper"} {
            border-color: map-deep-get($colors, "primary", "default");
        }
    }

    &__slide-nav-image-wrapper {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        border: 1px solid map-deep-get($colors, "gray", "default");
        cursor: pointer;
        display: inline-flex;
        padding: 10px;
        height: 100%;
        width: 100%;
    }

    &__slide-nav-image {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__brand {
        margin-top: 30px;
        margin-bottom: 20px;
        height: 60px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }

    &__brand-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
    }

    &__typeselect {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }
    }

    &__price-wrapper {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }
    }

    &__price {
        font-size: #{(30px / 16px) * 1rem};
        font-weight: 900;
        font-family: Nominee;
        margin-right: 20px;

        @include media-breakpoint-up(lg) {
            margin-right: 50px;
        }
    }

    &__price-vat {
        font-size: #{(15px / 16px) * 1rem};
    }

    &__info-and-accessoires {
        background-color: map-deep-get($colors, "gray", "light");
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding-top: 100px;
            margin-top: 100px;
        }
    }
}