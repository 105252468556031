﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus:hover {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
    max-width: rem-calc(1920);
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    color: map-deep-get($colors, "primary", "default");
    text-decoration: none;
}

b, strong {
    font-weight: 700;
}

::selection {
    background-color: rgba(map-deep-get($colors, "primary", "default"), 0.5);
    color: map-deep-get($colors, "white", "default");
}

.cc-revoke {
    display: none !important;
}