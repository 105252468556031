input, select {
    height: #{(40px / 16px) * 1rem};
    border: 1px solid  map-deep-get($colors, "gray", "default");
    padding: 10px 20px;
    width: 100%;
    transition: $transition-default;

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "black", "default");
    }
}

textarea {
    border: 1px solid map-deep-get($colors, "gray", "default");
    padding: 8px 16px;
    resize: vertical;
    min-height: #{(100px / 16px) *1rem};
    width: 100%;
    transition: border .3s ease-in-out;

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "black", "default");
    }
}

select {
    background: map-deep-get($colors, "white", "default");
    appearance: none;

    &::-ms-expand {
        display: none;
    }
}

.select-wrapper {
    position: relative;

    &__select {
        height: #{(40px / 16px) *1rem};
        color: map-deep-get($colors, "gray", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        font-weight: 500;
        padding: 0 20px;

        &:focus {
            border: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__dropdown-icon {
        position: absolute;
        right: #{(10px / 16px) *1rem};
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    //Directly referencing option element because of select context.
    /* stylelint-disable */
    option {
        font-size: 18px;
        padding: 12px 0;
    }
    /* stylelint-enable */
}

//Radio Button styling

.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: #{(8px / 16px) *1rem};
    $radioWrapper: &;

    &__input-wrapper {
        position: relative;
        width: #{(20px / 16px) *1rem};
        height: #{(20px / 16px) *1rem};
        margin-right: #{(9px / 16px) *1rem};
        float: left;
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            border: 1px solid map-deep-get($colors, "black", "default");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: #{(6px / 16px) *1rem};
        top: #{(6px / 16px) *1rem};
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "primary", "default");
        width: #{(8px / 16px) *1rem};
        height: #{(8px / 16px) *1rem};
        opacity: 0;
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: #{(-999px / 16px) *1rem};

        &:checked {
            + #{$radioWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: #{(14px / 16px) *1rem};
        cursor: pointer;
        font-weight: 400;
    }
    /* stylelint-enable */
}

.checkbox-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: #{(8px / 16px) *1rem};
    $checkboxWrapper: &;

    &__input-wrapper {
        position: relative;
        width: #{(20px / 16px) *1rem};
        height: #{(20px / 16px) *1rem};
        min-width: #{(20px / 16px) *1rem};
        min-height: #{(20px / 16px) *1rem};
        margin-right: #{(8px / 16px) *1rem};
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: #{(2px / 16px) *1rem};
            background-color: map-deep-get($colors, "white", "default");
            border: 1px solid map-deep-get($colors, "black", "default");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;

        svg {
            display: none;
        }

        &::before {
            font-family: "Font Awesome 5 Pro";
            content: "\f00c";
            font-weight: 900;
            display: inline-block;
            margin: 0.25rem;
            font-size: 0.8rem;
            color: map-deep-get($colors, "primary", "default");
        }
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: #{(-999px / 16px) *1rem};

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        cursor: pointer;
        margin-top: #{(2px / 16px) *1rem};
        font-weight: 400;
    }
    /* stylelint-enable */
}