.navigation {
    @include media-breakpoint-up(md) {
        display: inline-flex;
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 25px;
        padding-right: 15px;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 25px;
    }

    $navigation: &;

    &__item {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
    }

    &__link {
        font-weight: 100;
        font-family: Lemance;
        font-size: #{(14px / 16px) * 1rem};
        color: map-deep-get($header, "color", "default");
        line-height: 1.14em;

        &:hover{
            text-decoration: underline;
        }
    }

    &__sub {
        display: none;
    }

    &--shop{
        #{$navigation}__link {
            text-transform: uppercase;
            font-weight: 800;
            font-family: Nominee;
            font-size: #{(16px / 16px) * 1rem};
        }

        #{$navigation}__sub {
            // display: flex;
            position: absolute;
            top: 100%;
            left: 0;
            background: map-deep-get($colors, "white", "default");
            padding: 20px;
            z-index: 10;
            margin-top: 20px;
            width: 100vw;
            max-width: map-get($container-max-widths, "lg");

            @include media-breakpoint-up(lg) {
                padding: 40px;
            }
        }
    }

    &__sub--shop {
        > #{$navigation}__item {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }

            @include media-breakpoint-up(lg) {
                width: 25%;
            }

            @include media-breakpoint-up(xl) {
                width: 20%;
            }
        }

        #{$navigation}__items {
            #{$navigation}__item {
                display: block;
                padding: 0;
            }

            #{$navigation}__link {
                font-size: #{(14px / 16px) * 1rem};
                font-family: Lemance;
                font-weight: 100;
            }
        }
    }

    &--mobile-service {
        text-align: right;
        padding-right: 8px;
    }

    &--mobile {
        background: map-deep-get($header, "background", "default");
        margin: 0;
        padding: 0;
        transition: $transition-default;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        height: 0;
        overflow: hidden;

        #{$navigation}__item{
            width: 100%;
            padding: 10px;
        }
    }
}

.header--expanded{
    .navigation--mobile {
        height: calc(100vh - 90px);
        padding-top: 10px;
        padding-bottom: 10px;
    }
}