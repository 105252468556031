//Lemance

@font-face {
    font-family: Lemance;
    src: url("../fonts/lemance/Lemance-Italic.woff2") format("woff2"),
        url("../fonts/lemance/Lemance-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: Lemance;
    src: url("../fonts/lemance/Lemance-BoldItalic.woff2") format("woff2"),
        url("../fonts/lemance/Lemance-BoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: Lemance;
    src: url("../fonts/lemance/Lemance-Regular.woff2") format("woff2"),
        url("../fonts/lemance/Lemance-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: Lemance;
    src: url("../fonts/lemance/Lemance-Bold.woff2") format("woff2"),
        url("Lemance-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: block;
}

// Nominee

@font-face {
    font-family: Nominee;
    src: url("../fonts/nominee/Nominee-Bold.woff2") format("woff2"),
        url("../fonts/nominee/Nominee-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: Nominee;
    src: url("../fonts/nominee/Nominee-ExtraBold.woff2") format("woff2"),
        url("../fonts/nominee/Nominee-ExtraBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: Nominee;
    src: url("../fonts/nominee/Nominee-Black.woff2") format("woff2"),
        url("../fonts/nominee/Nominee-Black.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: Nominee;
    src: url("../fonts/nominee/Nominee-Heavy.woff2") format("woff2"),
        url("../fonts/nominee/Nominee-Heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: block;
}
