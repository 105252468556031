$header-height: ("small": 50px, "medium": 80px);

.header {
    $header-elm: &;
    background-color: map-deep-get($header, "background", "default");
    width: 100%;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: nowrap;
        //margin-bottom: 110px;
    }

    &__hamburger {
        display: flex;
    }

    &__trigger {
        font-family: Nominee;
        font-weight: 800;
        font-size: #{(12px / 16px) * 1rem};
        margin-left: 0.5em;
    }

    &__logo-wrapper {
        text-align: center;
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(md) {
            width: 174px;
            height: 126px;
            background-image: url("/images/template/logo-bg.svg");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: cover;
            margin-bottom: -74px;
            flex: 0 0 auto;

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                left: 0;
                right: 0;
                background: map-deep-get($colors, "white", "default");
                bottom: 99%;
            }
        }

        @include media-breakpoint-up(lg) {
            background-position: bottom right;
            height: 142px;
            margin-bottom: -111px;
            padding-top: 0;
            width: 227px;
        }

        @include media-breakpoint-up(xxl) {
            height: 215px;
            margin-bottom: -112px;
            width: 275px;
        }

        &--portal {
            @include media-breakpoint-up(md) {
                width: 212px;
                height: 130px;
                background-position: bottom right;
                background-size: cover;
                margin-bottom: -98px;

                &::before {
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: map-deep-get($colors, "white", "default");
                    bottom: 100%;
                }
            }
        }
    }

    &__wrapper {
        display: none;

        @include media-breakpoint-up(md) {
            align-self: self-start;
            display: flex;
            flex-wrap: wrap;
            flex-grow: 2;
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            display: inline-block;
            flex: 1 1 auto;
        }
    }

    &__top {
        background-color: map-deep-get($header, "background", "top");
        display: inline-flex;
        justify-content: space-between;
        padding: 12px 0;
        width: 100%;
    }

    &__top-left {
        display: inline-block;
    }

    &__top-right {
        display: inline-block;
        text-align: right;
    }

    &__phonenumber {
        color: map-deep-get($header, "color", "default");
        font-size: #{(14px / 16px) * 1rem};

        &:hover {
            text-decoration: underline;
        }

        &--button {
            padding: 15px;
            display: inline-block;
            justify-self: end;

            @include media-breakpoint-up(md) {
                margin: 0 10px;
            }
        }
    }

    &__back {
        color: map-deep-get($header, "color", "default");
        font-family: Nominee;
        font-weight: 800;
        font-size: #{(12px / 16px) * 1rem};
        margin-left: 0.5em;
    }

    &__phone {
        transform: rotate(100deg);
    }

    &__phone-wrapper {
        text-align: right;
    }

    &__bottom {
        background-color: map-deep-get($header, "background", "top");
        padding: 5px;
        margin-left: -10px;
        margin-right: -10px;

        @include media-breakpoint-up(md) {
            align-items: center;
            display: inline-flex;
            justify-content: space-between;
            margin-left: 0;
            margin-right: 0;
            padding-bottom: 10px;
            padding-top: 10px;
            width: 100%;
            background-color: map-deep-get($header, "background", "default");
        }
    }

    &__top,
    &__bottom {

        @include media-breakpoint-up(md) {
            padding-right: 25px;
        }

        @media (min-width: #{map_get($container-max-widths, "lg") + 120px}) {
            padding-right: 112px;
        }
    }

    &__bottom-left {
        display: inline-flex;
        position: relative;
        align-items: center;
    }

    &__botttom-right {
        display: inline-block;
        text-align: right;
        padding-right: 25px;
    }

    &--menuExpanded {
        #{$header-elm} {
            &__list {
                opacity: 1;
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform 0s linear;
            }

            &__listItem, &__subListItem {
                transform: translateY(0);
                opacity: 1;

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i * .05s;
                    }
                }
            }
        }
    }

    &--offset {
        position: absolute;
        transform: translateY(250px);
        top: -250px;
        left: 0;
        right: 0;
        z-index: 100;
    }

    &--scrolled {
        transition: top 0.3s ease-in-out;
        transform: translateY(0);
        top: 0;
        position: fixed;
    }
}
