.checkout-product {
    $padding-x: 40px;
    $padding-y: 35px;
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 20px;
    padding: #{$padding-y / 3} #{$padding-x / 3};

    &:nth-child(even) {
        background-color: map-deep-get($colors, "white", "default");
    }

    @include media-breakpoint-up(md) {
        padding: #{$padding-y / 2} #{$padding-x / 2};
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-y $padding-x;
    }

    &__image-wrapper {
        background-color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        text-align: center;
        padding: 20px;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 115px;
            height: 115px;
            padding: 15px;
        }

        @include media-breakpoint-up(lg) {
            width: 135px;
            height: 135px;
        }
    }

    &__image {
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__sku {
        font-family: Lemance, sans-serif;
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.714;
    }

    &__title {
        font-family: Nominee;
        font-size: #{(18px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1;
        color: map-deep-get($colors, "black", "default");
    }

    &__form {
        display: inline-block;
    }

    &__button {
        border: none;
        background: none;
        padding: 0;
        font-size: #{(16px / 16px) * 1rem};
        width: 50px;
        line-height: 50px;

        &--delete {
            cursor: pointer;
        }
    }

    &__amount {
        width: 50px;
        height: 50px;
        padding: 0;
        text-align: center;
    }

    &__delivery {
        font-family: Lemance, sans-serif;
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.714;
    }

    &__price {
        font-family: Nominee;
        font-size: #{(18px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1;
        text-align: right;
    }

    &__advice {
        background: map-deep-get($colors, "primary", "default");
        color: map-deep-get($colors, "black", "default");
        font-family: Lemance, sans-serif;
        font-size: #{(14px / 16px) * 1rem};
        font-weight: 800;
        line-height: 1.714;
        margin: 0 -#{$padding-x / 3} -#{$padding-y / 3};
        padding: 15px;
        position: relative;

        @include media-breakpoint-up(md) {
            padding-left: #{$padding-x / 2};
            padding-right: #{$padding-x / 2};
            margin: 0 -#{$padding-x / 2} -#{$padding-y / 2};
        }

        @include media-breakpoint-up(xl) {
            padding-left: $padding-x;
            padding-right: $padding-x;
            margin: 0 -#{$padding-x} -#{$padding-y};
        }
    }

    &__phonenumber {
        color: map-deep-get($colors, "black", "default");

        &:hover {
            text-decoration: underline;
        }
    }
}