.languages {
    display: inline-block;
    position: relative;
    $languages: &;

    @include media-breakpoint-up(sm) {
        margin-right: 10px;
    }

    &__language {
        display: inline-block;
        font-weight: 800;
        font-family: Nominee;
        font-size: #{(16px / 16px) * 1rem};
        text-transform: uppercase;
        padding: 5px;

        @include media-breakpoint-up(sm) {
            display: block;
        }

        &--active {
            display: none;
        }
    }

    &__link {
        color: map-deep-get($header, "color", "default");
        display: block;
    }

    &__active-language {
        display: inline-block;
        font-weight: 800;
        font-family: Nominee;
        font-size: #{(16px / 16px) * 1rem};
        text-transform: uppercase;
        padding: 5px;
        color: map-deep-get($header, "color", "default");

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__switch{
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: map-deep-get($header, "background", "default");
        z-index: 10;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        @include media-breakpoint-down(sm) {
            display: block;
            position: relative;
            top: auto;
            left: auto;

            #{$languages}__language {
                padding: 10px;
                
                &--active {
                    display: inline-block;
                }
            }
        }
    }

    &:hover{
        #{$languages}__switch {
            display: inline-block;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}