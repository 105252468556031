footer {
    overflow: hidden;
}

.footer {
    background-color: map-deep-get($footer, "background", "default");
    position: relative;
    margin-top: 50px;

    $elm: &;

    @include media-breakpoint-up(md) {
        margin-top: 12%;
        padding-top: 22vw;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 15vw;
        padding-top: 17vw;
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 10vw;
    }

    &__banner {
        background-color: map-deep-get($footer, "banner", "background");
        text-align: center;
        padding: 20px 10px;

        @include media-breakpoint-up(md) {
            text-align: left;
            transform-origin: top left;
            transform: rotate(-6deg);
            padding: 40px 30px;
            position: absolute;
            top: 0;
            left: -30px;
            right: -30px;

            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                height: 100%;
                background: map-deep-get($footer, "background", "default");
            }
        }
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        padding: 20px 10px;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        font-family: Nominee;
        font-size: #{(14px / 16px) * 1rem};
        font-weight: 800;
        line-height: 2.7em;
        display: block;

        &::after {
            font-family: "Font Awesome 5 Pro";
            content: "\f30b";
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            margin-left: 1em;

            @include media-breakpoint-up(md) {
                content: none;
            }
        }

        &--portal::after {
            content: none;
        }

        @include media-breakpoint-up(md) {
            line-height: 1.25em;
            margin-bottom: 1.5em;
            font-size: #{(18px / 16px) * 1rem};
            text-transform: none;
        }
    }

    &__navigation {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__navigation-item {
        font-family: Lemance;
        font-size: #{(14px / 16px) * 1rem};
        color: map-deep-get($footer, "color", "default");
        line-height: 1.5em;
        margin-bottom: 0.5em;
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }

    &__element {
        display: none;
        bottom: 0;
        height: 20.5vw;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: 20vw;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        path:first-child {
            fill: map-deep-get($colors, "primary", "default");
        }

        path:last-child {
            fill: map-deep-get($colors, "primary", "dark");
        }
    }

    &__bottom {
        background-color: map-deep-get($colors, "white", "default");
        text-align: center;
        padding: 20px 10px;

        @include media-breakpoint-up(md) {
            text-align: left;
            background-color: map-deep-get($footer, "background", "default");
            margin-top: 50px;
        }
    }

    &__copyright,
    &__webnl {
        color: map-deep-get($colors, "black", "default");
        font-size: #{(12px / 16px) * 1rem};
        font-weight: 100;
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            color: map-deep-get($footer, "color", "default");
            opacity: 0.6;
        }

        /* stylelint-disable */
        //beheerbaare content
        a {
            color: inherit;
        }
        /* stylelint-enable */
    }

    &__info-title {
        color: map-deep-get($colors, "black", "default");
        font-family: Lemance;
        font-weight: 100;
        font-size: #{(18px / 16px) * 1rem};

        @include media-breakpoint-up(md) {
            font-size: #{(24px / 16px) * 1rem};
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__info-name {
        color: map-deep-get($colors, "white", "default");
        font-family: Nominee;
        font-weight: 800;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    // portal part

    &__list-item {
        display: inline-block;
        white-space: nowrap;
        width: 100%;
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.5em;
        margin-bottom: 0.5em;
    }

    &__list-usp {
        display: inline-block;
        white-space: normal;
        vertical-align: top;
    }
}

.banner {
    &__title {
        color: map-deep-get($footer, "banner", "color");
        margin-bottom: 0.75em;
    }

    &__content,
    &__link {
        color: map-deep-get($footer, "banner", "color");
        margin: 0;
        padding: 0;
    }
}

.banner-item {
    align-items: center;
    display: flex;
    $elm: &;

    &__icon-wrapper {
        transform: rotate(6deg);
        display: inline-block;
        width: 4rem;

        svg {
            transition: all ease-in-out 0.15s;
            height: 100%;
            width: 100%;

            /* stylelint-disable */
            // path vanuit svg

            path {
                fill: map-deep-get($colors, "black", "default");
            }
            /* stylelint-enable */
        }
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        text-align: center;
        margin-top: -.65em;
        line-height: 1em;
        display: block;
        transform: rotate(-6deg);
        color: map-deep-get($colors, "white", "default");
        font-size: 1.2rem;
    }

    &__text-wrapper {
        display: inline-block;
        flex: 1;
        padding-left: 20px;
        position: relative;
    }

    &__text {
        transition: all ease-in-out 0.25s 0.2s;
        color: map-deep-get($colors, "black", "default");
        font-family: Nominee;
        font-weight: 800;
        font-size: #{(16px / 16px) * 1rem};
        line-height: 1.375em;
        background-image: linear-gradient(to right, map-deep-get($colors, "black", "default") 0%, map-deep-get($colors, "black", "default") 100%);
        background-repeat: no-repeat;
        background-position: 0 95%;
        background-size: 0 1px;
    }

    &:hover {
        #{$elm} {
            &__icon-wrapper svg {
                transform: rotate(30deg);
            }

            &__text {
                background-size: 100% 1px;
            }
        }
    }
}
