/*!
 * Font Awesome Pro 5.0.6 by @fontawesome - http://fontawesome.com
 * License - http://fontawesome.com/license (Commercial License)
 */
@import 'variables';


@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('#{$fa-font-path}/fa-regular-400.eot');
    src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.fa {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
}

@import 'fa-solid';
@import 'mixins';
@import 'core';
@import 'larger';
@import 'fixed-width';
@import 'list';
@import 'bordered-pulled';
@import 'animated';
@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
@import 'screen-reader';
