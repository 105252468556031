.hero {
    &__bg {
        background-position: center center;
        background-size: cover;
        margin-top: -50px;
        padding-top: 40%;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-top: -75px;
            padding-top: 30%;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 32.5%;
        }
    }

    &__container {
        padding: 20px 0;

        @include media-breakpoint-up(md) {
            padding: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        font-family: Nominee;
        font-size: #{(25px / 16px) * 1rem};
        font-weight: 900;
        line-height: 1.23;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: #{(30px / 16px) * 1rem};
        }

        @include media-breakpoint-up(lg) {
            font-size: #{(55px / 16px) * 1rem};
        }
    }

    &__content {
        font-size: #{(16px / 16px) * 1rem};
        font-weight: 800;
        font-family: Nominee;
        color: map-deep-get($colors, "white", "default");
        line-height: 1.23;
        margin-bottom: 2.25em;

        @include media-breakpoint-up(md) {
            margin-bottom: 3em;
            font-size: #{(18px / 16px) * 1rem};
        }
    }

    &__button {
        display: inline-block;
    }
}

.fetchimage {
    position: relative;
    
    &--home {
        /* stylelint-disable */
        // omdat de parent een home variant heeft

        .hero__bg {
            padding-top: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            @include media-breakpoint-up(md) {
                position: relative;
                padding-top: 40%;
                min-height: 350px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 32.5%;
                min-height: 450px;
            }
        }

        /* stylelint-enable */
    }

    &-wrapper {
        position: relative;

        @include media-breakpoint-up(md) {
            padding-left: 68px;
            padding-right: 68px;
        }

        @include media-breakpoint-up(xxl) {
            padding-left: 113px;
            padding-right: 113px;
        }

        @include media-breakpoint-up(md) {
            &--highlight {
                background: linear-gradient(to bottom, map-deep-get($colors, "primary", "default") 75%, #ffffff 75%);
            }
        }
    }
}