.steps {
    &__title {
        margin-bottom: 1em;
        margin-top: 0;
    }

    &__step {
        font-size: #{(14px / 16px) * 1rem};
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        $elm: &;

        &--step-3 {
            border-bottom: none;
        }

        #{$elm}-title {
            margin-bottom: 0.5em;
            margin-top: 0;
        }
    }
}