.main {
    overflow: hidden;
    max-width: 100%;
    min-height: 100vh;

    &__inner {
        padding-top: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 75px;
        }
    }
}

.grid-container {
    padding: 0 20px;

    @include media-breakpoint-up(lg) {
        padding: 0 30px;
    }
    @include media-breakpoint-up(xxl) {
        padding: 0;
    }
}

.template--products,
.template--product-detail {
    .main__inner {
        padding-top: 0;
    }
}