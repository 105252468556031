.brand {
    text-align: center;
    $logo-elm: &;

    &__logo{
        transition: $transition-default;
        filter: grayscale(100%);
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 100%;
        }

        @include media-breakpoint-up(lg) {
            max-width: 350px;
        }
    }

    &:hover #{$logo-elm}__logo {
        filter: grayscale(0);
    }
}