.tabnav {
    display: flex;
    flex-wrap: wrap;
    $tabnav: &;

    &__item {
        display: inline-block;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: -1px;

        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
            margin-bottom: 0;
            margin-right: -1px;
        }
    }

    &__link {
        background-color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        padding: 15px 18px;
        line-height: 1.25em;
        font-family: Nominee;
        font-size: #{(16px / 16px) * 1rem};
        font-weight: 800;
        cursor: pointer;
        width: 100%;

        &:hover,
        &:focus {
            outline: none;

            &:not(#{$tabnav}__link--active) {
                background-color: rgba(map-deep-get($colors, "primary", "default"), 0.75);
                border-color: rgba(map-deep-get($colors, "primary", "default"), 0.75);
            }
        }

        &--active {
            background-color: map-deep-get($colors, "primary", "default");
            border-color: map-deep-get($colors, "primary", "default");
        }
    }
}