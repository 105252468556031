.error{
    &__title {
        font-size: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 6rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 8rem;
        }
    }
}