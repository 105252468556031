.checkout-summary {
    $padding-x: 55px;
    $padding-y: 70px;
    background-color: map-deep-get($colors, "gray", "light");
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: 20px;
    padding: #{$padding-y / 4} #{$padding-x / 4};

    @include media-breakpoint-up(md) {
        padding: #{$padding-y / 2} #{$padding-x / 2};
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-y $padding-x;
    }

    &__label,
    &__value {
        text-align: right;
        font-size: #{(14px / 16px) * 1rem};
        line-height: 1.714em;

        &--total {
            font-size: #{(18px / 16px) * 1rem};
            font-family: Nominee;
            font-weight: 800;
        }
    }

    &__value {
        font-weight: 800;
    }

    &__total {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid map-deep-get($colors, "black", "default");
    }
}